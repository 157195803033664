import * as React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"

import "swiper/css/bundle"

import Card from "./card"
import { StaticImage } from "gatsby-plugin-image"

interface CardProps {
  radiusClasses: string
  cardColor: string
  title: string
  cardText: string
  titleColor: string
  textColor: string
  whitePlus: boolean
}

const CardZone = () => {
  return (
    <div className=" mt-8 mb-16 space-y-8 md:mx-16 ">
      <a className="anchor" id="ambitioperativi"></a>

      <p className="mx-auto  max-w-4xl px-8 py-14 text-center text-base font-medium leading-snug md:px-16 md:text-xl ">
        Nasciamo come competenza nel settore della mobilità e del facility
        management. Abbiamo fatto dell’ottimizzazione e la standardizzazione dei
        processi interni il nostro punto di forza per essere competitivi e
        distintivi sul mercato. Abbiamo esteso queste competenze per generare
        valore per le aziende che necessitavano di ottimizzare processi interni,
        monitorare e ridurre gli sprechi.
      </p>
      <h2 className="  text-center text-3xl font-bold md:pb-8">
        Ambiti operativi
      </h2>

      <ul className="hidden md:grid md:grid-cols-2 lg:grid-cols-4">
        {Cards.map((card, index) => (
          <li key={index}>{card}</li>
        ))}
      </ul>
      <Swiper
        className=" !z-0 md:hidden"
        modules={[Pagination]}
        centeredSlides={true}
        slidesPerView={1}
        pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={swiper => console.log(swiper)}
        breakpoints={{
          412: {
            slidesPerView: 1.2,
          },
          580: {
            slidesPerView: 1.7,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {Cards.map((card, index) => (
          <SwiperSlide key={index}>{card}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default CardZone

let cardText = [
  <>
    La ricerca tecnologica al servizio della vita delle persone: crediamo
    fortemente in questo e i nostri servizi di Facility Management ne sono
    l’esempio più importante.
  </>,
  <>
    Fra i bisogni contemporanei dell’uomo ne esiste uno trasversale a tutti e di
    cui nessuno può fare a meno: la sostenibilità.
    <br /> Ecco perché la ricerca di soluzioni a basso impatto ambientale non è
    solo uno degli ambiti del nostro business, ma uno dei nostri principi
    fondamentali.
  </>,
  <>
    Usare l’innovazione <br /> per la ricerca della perfezione: questo è il
    nostro punto fermo, che si tratti di tecnologia o di processi di lavoro.
    Studiamo tecnologie sempre più avanzate che permettano di controllare e
    migliorare la gestione, la sicurezza e l’efficienza dei dipendenti sul luogo
    lavoro.
  </>,
  <>
    Creare esperienze sempre più avanzate attraverso l’uso dell’innovazione,
    pensando al domani e a un mondo sempre più green. Un impegno verso il futuro
    che trova un suo chiaro esempio nelle nostre soluzioni di mobilità condivisa
    e sostenibile.
  </>,
]

let Cards = [
  <Card
    radiusClasses={"rounded-3xl"}
    cardColor="bg-[#FC6C49]"
    title="
    Facility Management"
    cardText={cardText[0]}
    titleColor="text-black"
    textColor="text-white"
    whitePlus={false}
  >
    <StaticImage src="../../images/cards/card-1.png" alt="bg-image" />
  </Card>,
  <Card
    radiusClasses={"rounded-full"}
    cardColor="bg-[#304AE9]"
    title="Sustainability"
    cardText={cardText[1]}
    titleColor="text-white"
    textColor="text-white"
    whitePlus={true}
  >
    <StaticImage src="../../images/cards/card-2.png" alt="bg-image" />
  </Card>,
  <Card
    radiusClasses={"rounded-t-full"}
    cardColor="bg-[#B2B0F7]"
    title="Process Optimization"
    cardText={cardText[2]}
    titleColor="text-white"
    textColor="text-black"
    whitePlus={true}
  >
    <StaticImage src="../../images/cards/card-3.png" alt="bg-image" />
  </Card>,
  <Card
    radiusClasses={"rounded-[8rem]"}
    cardColor="bg-[#FED718]"
    title="Mobility"
    cardText={cardText[3]}
    titleColor="text-white"
    textColor="text-black"
    whitePlus={true}
  >
    <StaticImage src="../../images/cards/card-4.png" alt="bg-image" />
  </Card>,
]
