import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Landing from "../components/landing"
import CardZone from "../components/cardzone"
import MethodZone from "../components/methodzone"
import Contacts from "../components/contacts"
import Sites from "../components/sites"

const IndexPage = () => {
  return (
    <>
      <Layout>
        <Seo title="Distribus" />
        <Landing />
        <CardZone />
        <MethodZone />
        <Sites />
        <Contacts />
      </Layout>
    </>
  )
}

export default IndexPage
