import * as React from "react"
import useHover from "../../hooks/useHover"

const Method = ({ classes, frontImage, backImage }) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <div ref={hoverRef} className={classes}>
      {isHovered ? backImage : frontImage}
    </div>
  )
}

export default Method
