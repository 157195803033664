import React from "react"
import HomeVideo from "../videos/home.mp4"
import { StaticImage } from "gatsby-plugin-image"

import AnchorLink from "react-anchor-link-smooth-scroll"

const Landing = () => {
  return (
    <div className="cursor-fancydefault relative flex h-[80vh] min-h-[680px] flex-col items-center justify-center gap-20 overflow-hidden">
      <video
        loop
        muted
        autoPlay
        playsInline
        className="absolute min-h-full object-cover object-center"
      >
        <source src={HomeVideo} type="video/mp4" />

        {/* TODO Add more video types like webm and ogg */}
        {/* TODO Maybe use an img tag with mp4 as gif */}
      </video>

      <div className="absolute bottom-16 max-w-lg space-y-2 px-10 text-center lg:bottom-20 lg:left-16 lg:text-left">
        <h1 className="relative text-lg font-bold text-white ">
          Business ideas for human needs.
        </h1>
        <p className="relative  text-base font-medium text-white">
          Sviluppiamo innovazione partendo dai bisogni di ognuno, per
          individuare soluzioni e tecnologie avanzate che possano migliorare la
          vita di tutti.
        </p>
      </div>

      <StaticImage
        className="max-w-[65%] lg:max-w-[35%] xl:max-w-[26%]"
        src="../images/landing/distribus_logo.png"
        loading="eager"
        placeholder="tracedSVG"
        alt="logo di distribus"
      />

      <AnchorLink
        className="max-w-[15%] sm:max-w-[11%] md:max-w-[9%] lg:max-w-[7%] xl:max-w-[5%]"
        href="#ambitioperativi"
      >
        <StaticImage
          placeholder="tracedSVG"
          imgClassName="object-contain"
          src="../images/landing/down_button.png"
          alt="pulsante per scendere"
          objectFit="contain"
          loading="eager"
        />
      </AnchorLink>
    </div>
  )
}

export default Landing
