import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import ImageButton from "../parts/imagebutton"

interface CardProps {
  children: React.ReactNode
  radiusClasses: string
  cardColor: string
  title: string
  cardText: JSX.Element
  titleColor: string
  textColor: string
  whitePlus: boolean
}

const Card = ({
  children,
  radiusClasses,
  cardColor,
  cardText,
  title,
  textColor,
  titleColor,
  whitePlus,
}: CardProps) => {
  const [turned, setTurned] = React.useState(false)
  return (
    <div
      className={`relative z-0 mx-10 mt-8 mb-20 grid aspect-[6/9] grid-cols-3 grid-rows-6 items-center justify-center md:mx-4 md:my-4 lg:mx-2 xl:mx-4 ${radiusClasses} overflow-hidden`}
    >
      <div className="absolute top-0 bottom-0 flex">{children}</div>

      <div className="relative z-[1] col-span-3 col-start-1 row-span-2 row-start-3 place-self-center px-4 ">
        <div
          className={`text-center text-3xl font-bold lg:text-2xl xl:text-3xl ${titleColor}`}
        >
          {title}
        </div>
      </div>

      {/* TODO Invert color of the plus sign */}
      <div
        className={`relative col-start-2 row-start-5 aspect-square place-self-center p-4 duration-200  md:p-4  lg:p-4 xl:p-6 ${
          whitePlus ? "invert" : "invert-0"
        } ${turned ? "rotate-90 " : "rotate-0 "}  `}
      >
        {/*TODO higher res +*/}
        <ImageButton className={""} onClick={() => setTurned(!turned)}>
          <StaticImage
            src="../../images/cards/plus.png"
            alt="find more button"
            placeholder="none"
            loading="eager"
          />
        </ImageButton>
      </div>

      <div
        className={`relative z-[2]  col-span-3 col-start-1 row-span-6 row-start-1 flex w-full items-center justify-center overflow-hidden px-8 sm:px-4 xl:px-6 ${cardColor} duration-200 ${
          turned
            ? "cursor-fancypointer h-[100%] opacity-100 "
            : "h-[0%] opacity-0 "
        } `}
        onClick={() => setTurned(!turned)}
      >
        <p
          className={`text-center text-base font-medium leading-tight  sm:text-sm  md:text-base lg:text-sm xl:text-sm 2xl:text-base ${textColor} `}
        >
          {cardText}
        </p>
      </div>
    </div>
  )
}

export default Card
