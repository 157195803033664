import React from "react"
import Form from "./form"

export default function Contacts() {
  return (
    <div className="grid grid-cols-1 bg-d_pink-900 p-8 lg:grid-cols-3 ">
      <div className="anchor" id="contatti"></div>
      <div className="col-span-1 row-start-3 flex flex-col items-center justify-center space-y-8 p-8 text-center lg:row-start-2 lg:items-start lg:justify-start lg:text-left ">
        <div className="space-y-1 text-lg font-bold">
          <p>info@distribus.it</p>
          <p>+39 0175.062769</p>
        </div>
        <div className="space-y-8 font-bold">
          <div>
            <p className=" font-normal"> Sede legale</p>
            <p>Via Cavour 15</p>
            <p>21013 Gallarate (VA)</p>
          </div>
          <div>
            <p className=" font-normal">Sede operativa</p>
            <p>Via Circonvallazione 19</p>
            <p>12037 Saluzzo (CN )</p>
          </div>
          <div>
            <p className=" font-normal">Sede operativa</p>
            <p>Copernico Martesana</p>
            <p>Viale Monza 259</p>
            <p>20126 Milano (MI)</p>
          </div>
        </div>
      </div>

      <h2 className="col-span-1 col-start-1 text-center text-4xl font-bold lg:col-span-3 lg:col-start-1 lg:row-start-1 lg:px-16 lg:py-4 lg:text-center lg:text-4xl">
        Contattaci
      </h2>
      <div className="col-span-1 col-start-1 row-start-2 lg:col-span-2 lg:col-start-2 lg:row-start-2">
        <Form />
      </div>
    </div>
  )
}
