import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"

import "swiper/css/bundle"
import { StaticImage } from "gatsby-plugin-image"
import useHover from "../../hooks/useHover"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Site = ({ text, link, children }) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <div className="mb-12 flex flex-col items-center space-y-8 lg:items-start lg:space-y-2  xl:space-y-8">
      <div className=" flex h-32 items-center ">{children}</div>

      <p className=" mx-2 h-48 max-w-lg text-center leading-tight sm:h-36 md:h-32 lg:h-52 lg:text-left xl:h-36 2xl:h-28">
        {text}
      </p>

      {/* TODO cambio immagine on hover */}

      <OutboundLink href={link} className="hover:cursor-fancypointer mx-2">
        <div className=" grid  " ref={hoverRef}>
          <div
            className={`${
              isHovered ? "opacity-0" : "opacity-100"
            } col-start-1 row-start-2 place-self-center`}
          >
            <StaticImage
              objectFit="contain"
              src="../../images/loghi/arrow_line_circle_b.svg"
              alt="Vai al sito"
              placeholder="tracedSVG"
            />
          </div>
          <div
            className={`${
              isHovered ? "opacity-100" : "opacity-0"
            } col-start-1 row-start-2 place-self-center`}
          >
            <StaticImage
              objectFit="contain"
              src="../../images/loghi/arrow_full_circle_b.svg"
              alt="Vai al sito"
              placeholder="tracedSVG"
            />
          </div>

          <p
            className={`col-start-1 row-start-1 mb-4 text-lg font-bold decoration-2 underline-offset-[6px] ${
              isHovered ? "no-underline" : "underline"
            }`}
          >
            Vai al sito
          </p>
        </div>
      </OutboundLink>
    </div>
  )
}

export default Site
