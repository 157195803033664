import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Method from "./method"

const MethodZone = () => {
  return (
    <div className=" space-y-16 bg-d_beige pt-12 pb-24">
      <div className="anchor" id="metodo"></div>
      <div className=" mx-8 max-w-4xl space-y-4 text-center lg:mx-16 lg:grid lg:max-w-none lg:grid-cols-3 lg:items-center lg:justify-center lg:space-y-0 lg:space-x-16 lg:text-left ">
        <h2 className=" text-[4rem] font-bold lg:text-center lg:text-[5rem] ">
          Metodo
        </h2>
        <p className=" col-span-2 mx-4 font-medium leading-snug ">
          Rendiamo scalabile il processo di trasformazione di un’idea in un
          marchio o un’impresa. Partiamo sempre da una necessità e da
          un’approfondita analisi del problema e del contesto in cui vive.
          Grazie alla collaborazione con università, centri di ricerca e centri
          di competenza realizziamo l’innovazione necessaria a soddisfare queste
          esigenze.
        </p>
      </div>

      <div className="mx-auto grid  max-w-md grid-cols-1 items-center justify-center gap-x-0 gap-y-8 px-8 lg:mx-16 lg:max-w-none lg:grid-cols-none ">
        <p className="row-start-1 text-center text-xl  font-bold underline underline-offset-[6px] lg:col-start-1 lg:row-start-1">
          Problema
        </p>

        <Method
          classes={
            " row-start-2 grid aspect-square lg:col-start-1 lg:row-start-2"
          }
          frontImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1`}
              src="../../images/method/Distribus_problema_w.png"
              alt="Distribus problema"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
          backImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1`}
              src="../../images/method/Distribus_problema_b.png"
              alt="Distribus problema"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
        />

        <div className="row-start-3 mx-8 flex items-center justify-center lg:col-start-2 lg:row-start-2">
          <div className="  rotate-90 lg:rotate-0">
            <StaticImage
              src="../../images/method/arrow_b.svg"
              alt="Arrow"
              placeholder="none"
            />
          </div>
        </div>

        <div className="row-start-4 flex justify-center lg:col-start-3 lg:row-start-1">
          <p className=" text-center text-xl font-bold underline underline-offset-[6px] ">
            Innovazione
          </p>
        </div>

        <Method
          classes={
            "  row-start-5 grid aspect-square lg:col-start-3 lg:row-start-2"
          }
          frontImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1 `}
              src="../../images/method/Distribus_soluzione_w.png"
              alt="Distribus soluzione"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
          backImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1 `}
              src="../../images/method/Distribus_soluzione_b.png"
              alt="Distribus soluzione"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
        />

        <div className="row-start-6 mx-8 flex items-center justify-center lg:col-start-4 lg:row-start-2">
          <div className="  rotate-90 lg:rotate-0">
            <StaticImage
              src="../../images/method/arrow_b.svg"
              alt="Arrow"
              placeholder="none"
            />
          </div>
        </div>
        <div className="row-start-7 flex justify-center lg:col-start-5 lg:row-start-1">
          <p className="text-center text-xl font-bold underline underline-offset-[6px]">
            Soluzione
          </p>
        </div>
        <Method
          classes={
            " row-start-[8] grid aspect-square lg:col-start-5 lg:row-start-2"
          }
          frontImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1`}
              src="../../images/method/Distribus_innovazione_w.png"
              alt="Distribus innovazione"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
          backImage={
            <StaticImage
              className={`col-span-1 col-start-1 row-span-1 row-start-1`}
              src="../../images/method/Distribus_innovazione_b.png"
              alt="Distribus innovazione"
              objectFit="contain"
              placeholder="tracedSVG"
              aspectRatio={1}
            />
          }
        />
      </div>
    </div>
  )
}

export default MethodZone
