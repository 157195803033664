import React from "react"
import { useForm } from "react-hook-form"

import axios from "axios"
import DataPopup from "../popup_data"

const API_PATH = "./api/index.php"

export default function Form({ className }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm()

  //Inviare i dati con ajax al php server
  const onSubmit = data => {
    console.log(data)
    axios({
      method: "post",
      url: API_PATH,
      headers: { "content-type": "application/json" },
      data: data,
    })
      .then(res => {
        console.log(res)

        if (res.data.sent) {
          reset(
            {
              name: "",
              email: "",
              surname: "",
              telephone: "",
              request: "",
              privacy: false,
            },
            {
              keepErrors: true,
              keepDirty: true,
              keepIsSubmitted: false,
              keepTouched: false,
              keepIsValid: false,
              keepSubmitCount: false,
            }
          )
        } else {
          console.log(res)
        }
        //notify form sent with popup
      })
      .catch(err => {
        //notify form error with popup
        console.log(err)
      })
  }

  console.log(watch("example")) // watch input value by passing the name of it

  const [privacy, setPrivacy] = React.useState(false)

  // TODO Sistemare span che spostano il contenuto del testo in una riga sotto
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`flex flex-col gap-4  p-8 lg:grid lg:grid-cols-2 ${className} `}
    >
      {/* register your input into the hook by invoking the "register" function */}
      <input
        type="text"
        {...register("name", { required: true })}
        placeholder="Nome*"
        className="col-span-1 rounded-md border-0 placeholder:text-[#B2B2B2] "
      />

      <input
        type="text"
        {...register("surname", { required: true })}
        placeholder="Cognome*"
        className="col-span-1 rounded-md border-0 placeholder:text-[#B2B2B2]"
      />
      <input
        type="email"
        {...register("email", { required: true })}
        placeholder="Email*"
        className="col-span-1 rounded-md border-0 placeholder:text-[#B2B2B2]"
      />

      <input
        type="tel"
        {...register("telephone", { required: false })}
        placeholder="Telefono"
        className="col-span-1 rounded-md border-0 placeholder:text-[#B2B2B2]"
      />
      <textarea
        {...register("request", { required: true })}
        placeholder="Richiesta*"
        className="col-span-2 rounded-md border-0 placeholder:text-[#B2B2B2]"
        rows={5}
      />

      <div>
        <input
          type="checkbox"
          id="privacy"
          name="privacy"
          {...register("privacy", { required: true })}
          className="col-span-1 mr-2 rounded-full border-0 "
        />

        <label
          className=" cursor-pointer underline underline-offset-4"
          onClick={() => setPrivacy(true)}
          onKeyDown={() => setPrivacy(true)}
          htmlFor="privacy"
        >
          Trattamento Dati
        </label>
      </div>
      <div className="flex flex-col py-4 font-bold lg:col-span-2">
        {errors.name && <span>Inserire nome</span>}
        {errors.cognome && <span>Inserire cognome</span>}
        {errors.email && <span>Inserire una mail valida</span>}
        {errors.richiesta && <span>Inserire una richiesta</span>}
        {errors.privacy && <span>Accettare il modulo trattamento dati</span>}
      </div>
      <input
        type="submit"
        value={"Invia richiesta"}
        className=" hover:cursor-fancypointer place-self-center rounded-full border-2 border-black py-3 px-12 text-xl font-semibold hover:bg-black hover:text-white lg:col-span-2 lg:place-self-start"
      />

      <DataPopup modalIsOpen={privacy} setIsOpen={setPrivacy} />
    </form>
  )
}
