import * as React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"

import "swiper/css/bundle"
import { StaticImage } from "gatsby-plugin-image"
import Site from "./site"

const Sites = () => {
  return (
    <div className=" mx-8 space-y-8 py-12 lg:mx-16">
      <div>
        <h2 className="text-center text-3xl font-bold">I nostri marchi</h2>
      </div>
      <ul className="hidden grid-cols-3 lg:grid lg:gap-x-12 xl:gap-x-24">
        {SiteSlides.map((slide, index) => (
          <li key={index}>{slide}</li>
        ))}
      </ul>
      <Swiper
        className=" !z-0 lg:hidden"
        modules={[Pagination]}
        centeredSlides={true}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {SiteSlides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Sites

const textDistribus =
  "Pulizia, sanificazione, distribuzione automatica: sono questi gli ambiti in cui la nostra esperienza pluriennale ci permette di fare la differenza, offrendo alle aziende una qualità di servizio estremamente ricercata in ogni dettaglio, dalla cura del cliente alla delivery del servizio."
const textNole =
  "Garantire a tutti un’esperienza di noleggio agile, moderna e senza pensieri. È questo l’obiettivo di Nolé: la società di noleggio flessibile di veicoli sostenibili, come auto ibride, furgoni, bici e monopattini elettrici."
const textWash4Green =
  "Un approccio innovativo e sostenibile anche in un settore fermo da anni come quello dell’autolavaggio, così che dealer, concessionari e titolari di flotte aziendali possano non solo risparmiare tempo e costi, ma farlo guardando al futuro con occhi più green."

const SiteSlides = [
  <Site text={textDistribus} link="https://www.distribus.it/facility/">
    <StaticImage
      objectFit="contain"
      className=" max-h-36"
      src="../../images/loghi/logo_distribus_old.png"
      alt="Logo Distribus"
    />
  </Site>,
  <Site text={textNole} link="https://nole4share.com/">
    <StaticImage
      objectFit="contain"
      className=" max-h-36"
      src="../../images/loghi/logo_nole.png"
      alt="Logo Nolè"
    />
  </Site>,
  <Site text={textWash4Green} link="https://www.wash4green.it/">
    <StaticImage
      objectFit="contain"
      className=" max-h-36"
      src="../../images/loghi/logo_wash4green.png"
      alt="Logo Wash4Green"
    />
  </Site>,
]
